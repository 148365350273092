$(function () {
    if(RESPONSIVE) {
        var $slideBars      = new $.slidebars(); // Create new instance of Slidebars
        var $slidebarLeft   = $('.slidebarLeft');
        var $slidebarRight  = $('.slidebarRight');
        var $sbLeft         = $('.sb-left');
        var $sbRight        = $('.sb-right');
        var $alert          = $('.alert');

        //Open left slidebar
        if($sbLeft.length > 0) {
            $slidebarLeft.on('click', function() {
                $slideBars.slidebars.toggle('left');
            });
        }

        //Open right slidebar
        if($sbRight.length > 0) {
            $slidebarRight.on('click', function() {
                $slideBars.slidebars.toggle('right');
            });

            $sbRight.on('click', '.closeSidebar', function(e) {
                e.preventDefault();
                $slideBars.slidebars.close();
            });

            $sbRight.on('openCart', function() {
                $slideBars.slidebars.open('right');
            });
        }

        var scrollDiff = 20;
        if ($alert.length > 0) {
            var firstAlert = $alert.first();
            if($(window).width() <= 1024) {
                scrollDiff = 85;
            }
            $('html, body, #sb-site').animate({
                scrollTop: firstAlert.offset().top - scrollDiff
            });
        }

        //Initialize fastclick to avoid the click delay on touchscreens.
        if($(window).width() <= 1024) {
            FastClick.attach(document.body);
        }
    }
});
